





























import Component from 'vue-class-component';

import UiButton from '@/components/ui/UiButton.vue';
import UiPageHeader from '@/components/ui/UiPageHeader.vue';
import CreateWalletNew from '@/components/wallets/CreateWalletNew.vue';

import { BaseVue } from '../../BaseVue';
import Connections from '../../components/org/Connections.vue';

@Component({
  components: {
    Connections,
    CreateWalletNew,
    UiButton,
    UiPageHeader,
  },
})
export default class ConnectionsClass extends BaseVue {
  public selectedTab = 0;
  public isCreatingConnection = false;
  public connections = [];

  onCreateConnection() {
    this.isCreatingConnection = true;
  }

  onBackToList() {
    this.isCreatingConnection = false;
  }
}
