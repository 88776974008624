


























































































































































































































































































































































































































































































































































































import gql from 'graphql-tag';
import { isEmpty } from 'lodash';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import {
  Category,
  Connection,
  NetsuiteBodyCustomField,
  NetsuiteCustomFieldAccessor,
  NetsuiteCustomFieldBodyAccessor,
  NetsuiteCustomFieldLineAccessor,
  NetsuiteCustomFieldRemoteType,
  NetsuiteCustomRecordInput,
  NetsuiteCustomRecordItem,
  NetsuiteLineCustomField,
  NetsuiteMetadataAccessorInput,
  NetsuiteSavedSearchesInput,
} from '@/api-svc-types';
import { BaseVue } from '@/BaseVue';
import UiButton from '@/components/ui/UiButton.vue';
import UiCheckbox from '@/components/ui/UiCheckbox.vue';
import UiDropdown from '@/components/ui/UiDropdown.vue';
import UiLoading from '@/components/ui/UiLoading.vue';
import UiSelect from '@/components/ui/UiSelect.vue';
import UiSelect2 from '@/components/ui/UiSelect2.vue';
import UiTextEdit from '@/components/ui/UiTextEdit.vue';

import UiFormLabel from '../ui/UiFormLabel.vue';

@Component({
  components: {
    UiSelect,
    UiSelect2,
    UiDropdown,
    UiCheckbox,
    UiButton,
    UiTextEdit,
    UiLoading,
    UiFormLabel,
  },
})
export default class EditNetsuiteConnectionNew extends BaseVue {
  @Prop({ required: true })
  public readonly connection!: Connection | null;

  @Prop()
  public readonly refresh?: () => void;

  accountCode = '';
  feeAccountCode = '';
  concurrencyLimit = '0';
  currencyRemoteId = '';
  subsidiary = '';
  customBodyFields: NetsuiteBodyCustomField[] = [];
  customLineFields: NetsuiteLineCustomField[] = [];
  savedSearches: NetsuiteSavedSearchesInput = {};
  customRecords: NetsuiteCustomRecordInput[] = [];

  defaultClassId = '';
  defaultLocationId = '';
  defaultDepartmentId = '';
  defaultRevenueDepartmentId = '';
  defaultExpenseDepartmentId = '';
  defaultAssetDepartmentId = '';
  defaultLiabilityDepartmentId = '';

  expandBasic = false;
  expandCustomBodyFields = false;
  expandCustomLineFields = false;
  expandSaveSearches = false;
  expandCustomRecords = false;

  isSaving = false;

  savedSearchTypes: (keyof NetsuiteSavedSearchesInput)[] = ['bills', 'invoices', 'vendors', 'customers'];

  remoteTypes = [
    {
      text: 'ISO Date',
      value: 'isoDate',
    },
    {
      text: 'String',
      value: 'string',
    },
    {
      text: 'Float',
      value: 'float',
    },
    {
      text: 'Integer',
      value: 'integer',
    },
    {
      text: 'Ref',
      value: 'ref',
    },
    {
      text: 'Boolean',
      value: 'bool',
    },
  ];

  bodyAccessors = [
    {
      text: 'Txn Date',
      value: 'txnDate',
    },
    {
      text: 'Payment Id',
      value: 'paymentId',
    },
    {
      text: 'To Wallet Address',
      value: 'toWalletAddress',
    },
    {
      text: 'From Wallet Address',
      value: 'fromWalletAddress',
    },
    {
      text: 'Txn Id',
      value: 'txnId',
    },
    {
      text: 'Exchange Rate',
      value: 'exchangeRate',
    },
    {
      text: 'Crypto Ticker',
      value: 'cryptoTicker',
    },
    {
      text: 'Crypto Amount',
      value: 'cryptoAmount',
    },
  ];

  lineAccessors = [
    {
      text: 'Exchange Rate',
      value: 'exchangeRate',
    },
    {
      text: 'Crypto Ticker',
      value: 'cryptoTicker',
    },
    {
      text: 'Crypto Amount',
      value: 'cryptoAmount',
    },
  ];

  public get categories(): Category[] {
    return this.$store.getters['categories/ENABLE_CATEGORIES'].filter((cat: Category) => {
      return cat.accountingConnectionId === this.connection?.id;
    });
  }

  public async save() {
    this.expandBasic = false;
    this.expandCustomBodyFields = false;
    this.expandCustomLineFields = false;
    this.expandSaveSearches = false;
    this.expandCustomRecords = false;
    this.isSaving = true;
    try {
      let records = this.customRecords && this.customRecords.length ? this.customRecords : undefined;
      // convert customRecords[x].items[xx].referenceMapping to a map
      if (records) {
        records = records.map((record) => {
          if (record.items) {
            record.items = record.items.map((item) => {
              if (item.referenceMapping) {
                const map: any = {};
                item.referenceMapping.forEach((ref) => {
                  map[ref.key] = ref.value;
                });
                item.referenceMapping = map;
              }
              return item;
            });
          }
          return record;
        });
      }

      const netsuite = {
        concurrencyLimit: Number(this.concurrencyLimit) > 0 ? Number(this.concurrencyLimit) : undefined,
        currencyRemoteId: this.currencyRemoteId ? this.currencyRemoteId : undefined,
        subsidiary: this.subsidiary ? this.subsidiary : undefined,
        customBodyFields: this.customBodyFields.length ? this.customBodyFields : undefined,
        customLineFields: this.customLineFields.length ? this.customLineFields : undefined,
        defaultClassId: this.defaultClassId ? this.defaultClassId : undefined,
        defaultLocationId: this.defaultLocationId ? this.defaultLocationId : undefined,
        defaultDepartmentId: this.defaultDepartmentId ? this.defaultDepartmentId : undefined,
        defaultRevenueDepartmentId: this.defaultRevenueDepartmentId ? this.defaultRevenueDepartmentId : undefined,
        defaultExpenseDepartmentId: this.defaultExpenseDepartmentId ? this.defaultExpenseDepartmentId : undefined,
        defaultAssetDepartmentId: this.defaultAssetDepartmentId ? this.defaultAssetDepartmentId : undefined,
        defaultLiabilityDepartmentId: this.defaultLiabilityDepartmentId ? this.defaultLiabilityDepartmentId : undefined,
        savedSearches: this.savedSearches && !isEmpty(this.savedSearches) ? this.savedSearches : undefined,
        customRecords: records,
      };
      const vars = {
        orgId: this.$store.state.currentOrg.id,
        connectionId: this.connection?.id ?? '',
        accountCode: this.accountCode,
        feeAccountCode: this.feeAccountCode,
        connectionSpecificFields: {
          netsuite,
        },
      };
      const resp = await this.$apollo.mutate({
        mutation: gql`
          mutation updateAccountingConnection(
            $orgId: ID!
            $connectionId: ID!
            $accountCode: String
            $feeAccountCode: String
            $connectionSpecificFields: AccountingConnectionSpecificFields
          ) {
            updateAccountingConnection(
              orgId: $orgId
              connectionId: $connectionId
              accountCode: $accountCode
              feeAccountCode: $feeAccountCode
              connectionSpecificFields: $connectionSpecificFields
            )
          }
        `,
        // Parameters
        variables: vars,
      });
      this.isSaving = false;
      if (resp.errors) {
        this.showErrorSnackbar('Problem: ' + resp.errors.join('<br />'));
      }

      if (resp.data && resp.data.updateAccountingConnection) {
        this.showSuccessSnackbar('Updated Net Suite Connection!');
        this.accountCode = '';
        this.feeAccountCode = '';
        this.concurrencyLimit = '0';
        this.currencyRemoteId = '';
        this.subsidiary = '';
        this.customBodyFields = [];
        this.customLineFields = [];
        this.defaultClassId = '';
        this.defaultLocationId = '';
        this.defaultDepartmentId = '';
        this.defaultRevenueDepartmentId = '';
        this.defaultExpenseDepartmentId = '';
        this.defaultAssetDepartmentId = '';
        this.defaultLiabilityDepartmentId = '';
        this.savedSearches = {};
        this.$emit('back');
        if (this.refresh) {
          this.refresh();
        }
      }
    } catch (err: any) {
      this.showErrorSnackbar('Problem: ' + err.message);
    } finally {
      this.isSaving = false;
    }
  }

  public onHide() {
    this.$emit('close');
  }

  addCustomBodyField() {
    this.customBodyFields.push({
      remoteId: '',
      remoteType: NetsuiteCustomFieldRemoteType.String,
      accessor: NetsuiteCustomFieldBodyAccessor.ExchangeRate,
    });
  }

  addCustomLineField() {
    this.customLineFields.push({
      remoteId: '',
      remoteType: NetsuiteCustomFieldRemoteType.String,
      accessor: NetsuiteCustomFieldLineAccessor.ExchangeRate,
    });
  }

  addSavedField(name: 'bills' | 'invoices' | 'vendors' | 'customers') {
    this.$set(this.savedSearches, name, {
      type: '',
      savedSearchId: '',
      metadataAccessors: [],
    });
  }

  addAccessor(accessors: NetsuiteCustomFieldAccessor[]) {
    accessors.push({
      key: '',
      remoteId: '',
      remoteType: '',
      metadataLabel: '',
      type: '',
    });
  }

  addCustomRecord() {
    this.customRecords.push({
      type: 'wallet',
      remoteId: '',
      name: '',
      scriptId: '',
      items: [
        {
          remoteId: '',
          remoteType: '',
          name: 'address',
        },
        {
          remoteId: '',
          remoteType: '',
          name: 'networkId',
        },
        {
          remoteId: '',
          remoteType: '',
          name: 'isValidated',
        },
        {
          remoteId: '',
          remoteType: '',
          name: 'lastValidatedSEC',
        },
        {
          remoteId: '',
          remoteType: '',
          name: 'contactId',
        },
        {
          remoteId: '',
          remoteType: '',
          name: 'assetId',
        },
      ],
    });
  }

  removeCustomRecord(idx: number) {
    this.customRecords.splice(idx, 1);
  }

  addCustomRecordItem(items: NetsuiteCustomRecordItem[]) {
    items.push({
      remoteId: '',
      remoteType: '',
      name: '',
    });
  }

  removeCustomRecordItem(items: NetsuiteCustomRecordItem[], idx: number) {
    items.splice(idx, 1);
  }

  addMetadataAccessor(metaDataAccessors: NetsuiteMetadataAccessorInput[]) {
    metaDataAccessors.push({
      type: '',
      path: '',
      accessors: [],
    });
  }

  addRefMapping(accessor: any) {
    if (!accessor.referenceMapping) {
      this.$set(accessor, 'referenceMapping', []);
    }
    accessor.referenceMapping.push({
      key: '',
      value: '',
    });
  }

  removeRefMapping(accessor: any, idx: number) {
    accessor.referenceMapping.splice(idx, 1);
  }

  removeMetadataAccessor(metaDataAccessors: NetsuiteMetadataAccessorInput[], idx: number) {
    metaDataAccessors.splice(idx, 1);
  }

  removeAccessor(accessors: NetsuiteCustomFieldAccessor[], idx: number) {
    accessors.splice(idx, 1);
  }

  removeCustomBodyField(idx: number) {
    this.customBodyFields = this.customBodyFields.filter((f, i) => i !== idx);
  }

  removeCustomLineField(idx: number) {
    this.customLineFields = this.customLineFields.filter((f, i) => i !== idx);
  }

  back() {
    this.$emit('back');
  }

  mounted() {
    this.onConnectionPropsChanged();
  }

  // @Watch('connection', { deep: true })
  onConnectionPropsChanged() {
    const { accountCode, feeAccountCode, connectionSpecificFields } = this.connection ?? {};
    const {
      concurrencyLimit,
      currencyRemoteId,
      subsidiary,
      customBodyFields,
      customLineFields,
      defaultClassId,
      defaultLocationId,
      defaultDepartmentId,
      defaultRevenueDepartmentId,
      defaultExpenseDepartmentId,
      defaultAssetDepartmentId,
      defaultLiabilityDepartmentId,
      savedSearches,
      customRecords,
    } = (connectionSpecificFields as any) ?? {};
    this.accountCode = accountCode ?? '';
    this.feeAccountCode = feeAccountCode ?? '';
    this.concurrencyLimit = concurrencyLimit?.toString() ?? '0';
    this.currencyRemoteId = currencyRemoteId ?? '';
    this.subsidiary = subsidiary ?? '';
    this.customBodyFields = customBodyFields
      ? customBodyFields.map((field: any) => {
          const { type, ...otherAttrs } = field;
          return otherAttrs;
        })
      : [];
    this.customLineFields = customLineFields
      ? customLineFields.map((field: any) => {
          const { type, ...otherAttrs } = field;
          return otherAttrs;
        })
      : [];
    this.defaultClassId = defaultClassId ?? '';
    this.defaultLocationId = defaultLocationId ?? '';
    this.defaultDepartmentId = defaultDepartmentId ?? '';
    this.defaultRevenueDepartmentId = defaultRevenueDepartmentId ?? '';
    this.defaultExpenseDepartmentId = defaultExpenseDepartmentId ?? '';
    this.defaultAssetDepartmentId = defaultAssetDepartmentId ?? '';
    this.defaultLiabilityDepartmentId = defaultLiabilityDepartmentId ?? '';
    this.savedSearches = savedSearches ?? {};
    this.customRecords = customRecords ?? [];

    this.customRecords.forEach((record: any) => {
      record?.items.forEach((item: any) => {
        item.referenceMapping = Object.keys(item.referenceMapping ?? {})?.map((x) => ({
          key: x,
          value: item.referenceMapping[x],
        }));
      });
    });
  }
}
